import { AlarmNotification } from '@hakimo-ui/hakimo/types';
import { Button } from '@hakimo-ui/shared/ui-base';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import AddEditAlarmNotificationPreference from './AddEditAlarmNotificationPreference';
import DeleteAlarmNotificationPreference from './DeleteAlarmNotificationPreference';

interface Props {
  isEditing: boolean;
  item: AlarmNotification;
  editingRow: string;
  onClickEdit: (userId: string) => void;
  onCancelEdit: () => void;
}

export function ActionAlarmNotificationPreferences(props: Props) {
  const { isEditing, item, editingRow, onClickEdit, onCancelEdit } = props;

  return (
    <div>
      {isEditing && editingRow === item.id ? (
        <div className="mt-2 flex flex-row gap-x-6">
          <AddEditAlarmNotificationPreference
            item={item}
            onCancelEdit={onCancelEdit}
          />
          <Button variant="outline" onClick={onCancelEdit}>
            Cancel
          </Button>
        </div>
      ) : (
        <div
          className={clsx(
            isEditing && editingRow !== item.id && 'pointer-events-none',
            'mt-2 flex  flex-row gap-x-6'
          )}
        >
          <Button
            variant="icon"
            onClick={() => onClickEdit(item.id)}
            className="gap-1"
          >
            <PencilSquareIcon className="h-6 w-6" />
            Edit
          </Button>
          <DeleteAlarmNotificationPreference item={item} />
        </div>
      )}
    </div>
  );
}
export default ActionAlarmNotificationPreferences;
