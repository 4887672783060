import { useUser } from '../contexts/user-profile';

export function useIsSupportRole() {
  const { roles } = useUser();
  return (
    roles.includes('Hakimo Support') ||
    roles.includes('MSP Manager') ||
    roles.includes('GSOC Manager')
  );
}
