/* eslint-disable max-lines */
import {
  ArrowPointerIcon,
  PanHandIcon,
  ResetIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import { Button } from '@hakimo-ui/shared/ui-base';
import {
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

interface Props {
  video: HTMLVideoElement;
}
export function ZoomActions(props: Props) {
  const { video } = props;
  const [zoomLevel, setZoomLevel] = useState(1);
  const removeListenersRef = useRef<() => void>();
  const [isPanning, setIsPanning] = useState(false);

  useEffect(() => {
    if (isPanning) {
      video.style['cursor'] = 'grab';
      removeListenersRef.current = addPanEvents(video);
    } else {
      video.style['cursor'] = 'auto';
      removeListenersRef.current && removeListenersRef.current();
    }
  }, [isPanning, video]);

  const onZoomIn = () => {
    const newZoomLevel = zoomLevel + 0.1;
    video.style['transform'] = 'scale(' + newZoomLevel + ')';
    setZoomLevel(newZoomLevel);
    setIsPanning(true);
  };

  const onZoomOut = () => {
    if (zoomLevel > 1) {
      const newZoomLevel = zoomLevel - 0.1;
      video.style['transform'] = 'scale(' + newZoomLevel + ')';
      setZoomLevel(newZoomLevel);
    }
  };

  const onPan = () => setIsPanning(!isPanning);

  const onPointer = () => {
    setIsPanning(false);
  };

  const onReset = () => {
    video.style['transform'] = 'scale(1)';
    video.style.translate = '0px 0px';
    setIsPanning(false);
    setZoomLevel(1);
  };

  return (
    <div
      className={clsx(
        'group/zoom from-dark-bg/80 flex items-center justify-center gap-2 rounded-bl-md bg-gradient-to-l to-zinc-500/50 p-1 pr-2'
      )}
    >
      <Button
        variant="icon"
        onClick={onPointer}
        title="Pointer"
        className={clsx(
          'hidden focus:ring-offset-0 group-hover/zoom:block',
          !isPanning
            ? 'bg-primary-600/50 enabled:hover:bg-primary-700/80 dark:enabled:hover:bg-primary-700/80'
            : 'enabled:hover:bg-slate-800'
        )}
      >
        <ArrowPointerIcon className="fill-dark-text stroke-dark-text h-5 w-5" />
      </Button>
      <Button
        variant="icon"
        onClick={onPan}
        title="Pan"
        className={clsx(
          'hidden focus:ring-offset-0 group-hover/zoom:block',
          isPanning
            ? 'bg-primary-600/50 enabled:hover:bg-primary-700/80 dark:enabled:hover:bg-primary-700/80'
            : 'enabled:hover:bg-slate-800'
        )}
      >
        <PanHandIcon className="fill-dark-text hover:fill-ondark-text-1 h-5 w-5 pr-1" />
      </Button>
      <Button
        variant="icon"
        onClick={onReset}
        title="Reset"
        className="hidden focus:ring-offset-0 enabled:hover:bg-slate-800 group-hover/zoom:block"
      >
        <ResetIcon className="fill-dark-text hover:fill-ondark-text-1 h-5 w-5" />
      </Button>
      <Button
        variant="icon"
        onClick={onZoomIn}
        title="Zoom In"
        className="focus:ring-offset-0 enabled:hover:bg-slate-800"
      >
        <MagnifyingGlassPlusIcon className="stroke-dark-text hover:stroke-ondark-text-1 h-5 w-5" />
      </Button>
      <Button
        variant="icon"
        onClick={onZoomOut}
        disabled={zoomLevel <= 1}
        title="Zoom Out"
        className="focus:ring-offset-0 enabled:hover:bg-slate-800"
      >
        <MagnifyingGlassMinusIcon className="stroke-dark-text hover:stroke-ondark-text-1 h-5 w-5" />
      </Button>
    </div>
  );
}
function addPanEvents(video: HTMLVideoElement) {
  const videoContainer = video.parentElement;
  if (!videoContainer) return;
  let isDragging = false;
  let startCoords: number[] = [];
  let last = [0, 0];
  const startDrag = (e: MouseEvent) => {
    isDragging = true;
    video.style.cursor = 'grabbing';
    startCoords = [
      e.offsetX - last[0], // set start coordinates
      e.offsetY - last[1],
    ];
  };
  const stopDrag = (e: MouseEvent) => {
    if (!isDragging) return;
    isDragging = false;
    video.style.cursor = 'grab';
    last = [
      e.offsetX - startCoords[0], // set last coordinates
      e.offsetY - startCoords[1],
    ];
    e.stopPropagation();
  };
  const whileDrag = (e: MouseEvent) => {
    if (!isDragging) return;
    const x = e.offsetX;
    const y = e.offsetY;
    video.style.translate = `${x - startCoords[0]}px ${y - startCoords[1]}px`;
  };
  video.addEventListener('mousedown', startDrag);
  videoContainer.addEventListener('mousemove', whileDrag);
  videoContainer.addEventListener('mouseup', stopDrag, true);
  videoContainer.addEventListener('mouseout', stopDrag);
  return () => {
    video.removeEventListener('mousedown', startDrag);
    videoContainer.removeEventListener('mousemove', whileDrag);
    videoContainer.removeEventListener('mouseup', stopDrag);
    videoContainer.removeEventListener('mouseout', stopDrag);
  };
}
