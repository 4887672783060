import {
  useCanAddCamera,
  useDebounce,
  useExportCsv,
  useIsSupportRole,
} from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner, InputField } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { AddCamera } from './add-camera/AddCamera';
import { CamFilterValues } from '@hakimo-ui/hakimo/types';
import { getFormattedExportData, getSearchParams } from './util';
import { useCameras } from '@hakimo-ui/hakimo/data-access';

export interface Props {
  isLoading: boolean;
  isDefault: boolean;
  onClickOpenFilterPanel: () => void;
  onSearch: (value: string) => void;
  camFilters: CamFilterValues;
  isDataValid: boolean;
}

export function CameraListHeader(props: Props) {
  const {
    isLoading,
    onClickOpenFilterPanel,
    onSearch,
    isDefault,
    camFilters,
    isDataValid,
  } = props;
  const [addCamera, setAddCamera] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const canAddCamera = useCanAddCamera();
  const [searchCameraValue, setSearchCameraValue] = useState('');
  const debouncedCameraValue = useDebounce(searchCameraValue, 400);
  useEffect(() => {
    onSearch(debouncedCameraValue);
  }, [debouncedCameraValue, onSearch]);

  const exportSearchParams = getSearchParams(camFilters);
  const { refetch: fetchExportData } = useCameras(exportSearchParams, false);

  const { exportCsv } = useExportCsv();

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { data } = await fetchExportData();
      if (!data?.items) {
        throw new Error('Data Fetching Failed');
      }

      const formattedData = getFormattedExportData(data.items);
      if (!Array.isArray(formattedData) || formattedData.length === 0) {
        throw new Error('Formatted data is invalid or empty');
      }
      exportCsv({
        formattedData,
        fileName: 'hakimo-cameras-export.csv',
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error(
        `Export Failed: ${
          error instanceof Error ? error.message : String(error)
        }`
      );
    } finally {
      setIsExporting(false);
    }
  };

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCameraValue(e.target.value);
  };

  const isSupportRole = useIsSupportRole();
  const DisableExport = !isDataValid || !isSupportRole;

  return (
    <>
      <div className="flex flex-shrink-0 flex-col gap-4  py-1 pl-1 pr-4">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <Button onClick={() => setAddCamera(true)}>Add Camera</Button>
            {isLoading && <HakimoSpinner />}
          </div>
          <div className="">
            <span className="mr-2">
              <Button
                variant="icon"
                onClick={handleExport}
                loading={isExporting}
                title="Download Data"
                disabled={DisableExport}
              >
                <ArrowDownTrayIcon className="w-5" />
              </Button>
            </span>
            <Button
              variant="icon"
              onClick={onClickOpenFilterPanel}
              badge={!isDefault}
            >
              <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
            </Button>
          </div>
        </div>
        <div className=" flex flex-row items-start justify-start gap-4">
          <InputField
            label=""
            onChange={onSearchValChange}
            value={searchCameraValue}
            type="search"
            placeholder="Search camera..."
          />
        </div>
      </div>
      {canAddCamera && addCamera && (
        <AddCamera onClose={() => setAddCamera(false)} />
      )}
    </>
  );
}

export default CameraListHeader;
