/* eslint-disable @typescript-eslint/no-explicit-any */
import { Selectable } from '@hakimo-ui/shared/ui-base';

export type MultiSelectFilterValue = {
  values: Selectable[];
  negative: boolean;
};
export type CameraMultiSelectFilterValue = {
  values: CameraSelectable[];
  negative: boolean;
};
export interface CameraSelectable extends Selectable {
  location_id?: string;
  location_name?: string;
}
export type EmployeeFilterValue = MultiSelectFilterValue;
export type LocationFilterValue = MultiSelectFilterValue;
export type DoorNameFilterValue = MultiSelectFilterValue;
export type CameraFilterValue = CameraMultiSelectFilterValue;
export type Point = [number, number];
export type PointSize = 'small' | 'medium' | 'large';
export type Marker = { point: Point; color?: string; size?: PointSize };
export type Polygon = {
  points: Point[];
  color?: string;
  pointSize?: PointSize;
  closed?: boolean;
  tag?: string;
};
export type TenantFilterValue = {
  values: Selectable[];
  negative: boolean;
};

export type SourceEntityNameFilterValue = {
  values: Selectable[];
  negative: boolean;
};

export interface TableauUrlResponse {
  tableauUrl: string;
}

export interface Linkable {
  id: string;
  name: string;
}

export interface PagedResponseDTO<T> {
  items: T[];
  total: number;
}

export type HttpClient = <T>(
  requestInfo: RequestInfo,
  options?: RequestOptions<T>
) => Promise<T>;

export interface RequestOptions<T> {
  noAuth?: boolean;
  /** Use this function to run any side-effects */
  tap?: (response: Response) => void;
  responseModifier?: (response: Response) => Promise<T>;
}

export type VideoControlsList =
  | 'noFullscreen'
  | 'noVideoTimeline'
  | 'noLoader'
  | 'noPlaybackRate'
  | 'noForwardBackward'
  | 'noAudio'
  | 'onlyTime'
  | 'noPlayPause'
  | 'none';

export enum DetectionType {
  PERSON = 'person',
  VEHICLE = 'vehicle',
  INCORRECT_DETECTION = 'incorrect detection',
}

export interface StepperState {
  step: number;
  detectionType: DetectionType | undefined;
  comment: string;
}

export enum WsConnectionState {
  PENDING = 'pending',
  CONNECTED = 'connected',
  CLOSED = 'closed',
  ERROR = 'error',
  RECONNECTING = 'reconnecting',
}

export interface WSConnection {
  connectionState: WsConnectionState;
  subscribe?: (topid: string, callback: (data: any) => void) => () => void; // returns an unsubscribe callback
  send?: (message: any) => void;
  disconnect?: () => void;
}
