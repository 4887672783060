import { WSConnection, WsConnectionState } from '@hakimo-ui/hakimo/types';
import { createContext, useContext } from 'react';

const defaultValue: WSConnection = {
  connectionState: WsConnectionState.PENDING,
};

export const ScanWsContext = createContext(defaultValue);

export function useScanWs() {
  const httpClient = useContext(ScanWsContext);
  return httpClient;
}
