import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
interface Props {
  timezone: string;
}

export function LiveClock(props: Props) {
  const { timezone } = props;
  const [time, setTime] = useState(
    dayjs().tz(timezone).format('ddd hh:mm:ss A')
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs().tz(timezone).format('ddd hh:mm:ss A'));
    }, 1000);

    return () => clearInterval(interval);
  }, [timezone]);

  return (
    <div className="space-x-2 font-mono text-xs">
      <span>{time}</span>
      <span>({timezone})</span>
    </div>
  );
}
