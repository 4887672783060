import { Selectable } from '@hakimo-ui/shared/ui-base';
import { Permission } from './permissions';

export interface UserDTO {
  items: User[];
  total: number;
}
export interface AddUserPayload {
  name: string;
  email: string;
  roles: string[];
  password?: string;
}
export interface ModifyUserPayload {
  name?: string;
  roles?: string[];
}

export type ModifyUserMspTenantsAction = 'assign' | 'remove';

export interface ModifyUserMspTenantsPayload {
  users: string[];
  mspTenants: string[];
  action: ModifyUserMspTenantsAction;
}

export interface ModifyUserMspLocationsPayload {
  users: string[];
  mspLocations: number[];
}

export interface DeleteUserPayload {
  enabled?: boolean;
}

export type Role =
  | 'Hakimo Support'
  | 'MSP Operator'
  | 'MSP Manager'
  | 'GSOC Manager'; // add as needed

export interface User {
  name?: string | null;
  email: string;
  roles: Role[];
  id: string;
  userIntercomHash: string;
  permissions: Permission[];
  tenant: Selectable;
  mspTenants: Selectable[] | null;
  mspLocations: Selectable[] | null;
  visionTenants: Selectable[] | null;
  enabled: boolean;
}

export interface UpdateSelfPayload {
  name?: string;
}

export interface UserEnabledFilter {
  showEnabled: boolean;
  showNotEnabled: boolean;
}

export interface UserListFilters {
  names: string[];
  emails: string[];
  roles: string[];
  enabled: UserEnabledFilter;
}

export type UserRole = string;

export enum SystemNotificationPreference {
  OFF = 'off',
  INACTIVE = 'inactive',
  ALWAYS = 'always',
}
