/* eslint-disable max-lines */
import {
  DayGroupRange,
  LocationAlarmCamera,
  MotionDetectionWindow,
  Situation,
  SOPTalkdownType,
  SOPWorkflow,
} from '@hakimo-ui/hakimo/types';
import { getPreviousDayOfWeek } from '@hakimo-ui/hakimo/util';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { atom } from 'jotai';
import {
  DEFAULT_SOP_QUICK_RESOLVE,
  DEFAULT_SOP_SITUATIONS,
} from '../sop-manager/utils';
dayjs.extend(utc);
dayjs.extend(dayjsTimezone);

export enum EscalationStep {
  PRE_ESCALATE = 'pre_escalation',
  ESCALATION = 'escalation',
}

export enum WorkflowItemType {
  ESCALATE = 'escalate_alarm',
  RESOLVE = 'resolve_alarm',
  STEPS = 'workflow_steps',
}

export enum SOPStepType {
  STATIC_TALKDOWN = 'staticTalkdown',
  DYANMIC_TALKDOWN = 'dynamicTalkdown',
  FIRST_STEP = 'firstStep',
}
export interface DayLocationMonitoringConfig {
  timezone: string;
  dayOfWeek: string;
  ranges?: DayGroupRange[];
}

export const currentStepAtom = atom<number>(0);
export const isSOPWorkflowEnabledAtom = atom<boolean>(true);

export type SOPStep = {
  type: SOPStepType;
  title: string;
  yesCallback?: () => void;
  noCallback?: () => void;
  actionCallback?: (actionText: string) => void;
  exceptions?: string[];
  notes?: string[];
  talkdownText?: string;
  situations?: Situation[];
};

export const getSOPSteps = (
  navigateToNextStep: (actionText?: string) => void,
  navigateToResolve: () => void,
  sop: SOPWorkflow,
  escalate: (actionText?: string) => void,
  isSpeakerMapped: boolean
): SOPStep[] => {
  const sopSteps: SOPStep[] = [
    {
      type: SOPStepType.FIRST_STEP,
      title: 'Do you see any person or moving vehicle?',
      exceptions: sop.exceptions?.length > 0 ? sop.exceptions : [],
      notes: sop?.notes?.length > 0 ? sop.notes : [],
      situations: sop.situations?.length > 0 ? sop.situations : [],
      actionCallback: navigateToNextStep,
    },
  ];

  if (
    sop.isTalkdownEnabled &&
    sop.talkdowns &&
    sop.talkdowns.length > 0 &&
    isSpeakerMapped
  ) {
    sop.talkdowns.forEach((talkdown) => {
      sopSteps.push({
        type:
          talkdown.type === SOPTalkdownType.STATIC
            ? SOPStepType.STATIC_TALKDOWN
            : SOPStepType.DYANMIC_TALKDOWN,
        title:
          talkdown.type === SOPTalkdownType.STATIC
            ? 'Did the person or vehicle move away from the premises?'
            : 'Did they move away from the premises?',
        talkdownText: talkdown.text,
        yesCallback: navigateToResolve,
        noCallback: navigateToNextStep,
      });
    });
  }
  const lastStep = sopSteps[sopSteps.length - 1];
  if (lastStep.type === SOPStepType.FIRST_STEP) {
    lastStep.actionCallback = escalate;
  } else {
    // talkdown case
    lastStep.noCallback = escalate;
  }

  return sopSteps;
};

export enum SOPTab {
  SOP_WORKFLOW = 'sopWorkflow',
  SOP_TEXT = 'sop_text',
}

export const sopTabs = [
  { id: SOPTab.SOP_WORKFLOW, name: 'SOP Workflow' },
  { id: SOPTab.SOP_TEXT, name: 'SOP Overview' },
];

export function getLatestCameraWithTalkdown(
  cameras: LocationAlarmCamera[]
): LocationAlarmCamera | null {
  const talkdownEnabledCameras = cameras.filter((cam) => cam.isTalkdownEnabled);
  return talkdownEnabledCameras.length > 0 ? talkdownEnabledCameras[0] : null;
}

// time format is HH:MM:SS
export const convertToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 60 * 60 + minutes * 60 + (seconds ?? 0);
};

export function getTodaysMonitoringConfig(
  detectionWindow: MotionDetectionWindow
): DayLocationMonitoringConfig {
  if (!detectionWindow) {
    return { timezone: 'UTC', dayOfWeek: '', ranges: undefined };
  }
  const locTimezone = detectionWindow.timezone;

  const nowInTz = dayjs().tz(locTimezone);
  const dayOfWeekToday = nowInTz.format('dddd').toUpperCase();
  const dayOfWeekYesterday = getPreviousDayOfWeek(dayOfWeekToday);

  const dayGroupRanges = detectionWindow.dayGroupRanges;
  const todayGroupRanges =
    dayGroupRanges.find((group) => group.daysOfWeekTz.includes(dayOfWeekToday))
      ?.ranges ?? [];

  todayGroupRanges.forEach((range) => {
    if (convertToSeconds(range.startAtTz) >= convertToSeconds(range.endAtTz)) {
      range.endAtTz = '23:59:59';
    }
  });

  const yesterdayGroupRanges =
    dayGroupRanges.find((group) =>
      group.daysOfWeekTz.includes(dayOfWeekYesterday)
    )?.ranges ?? [];

  yesterdayGroupRanges.forEach((range) => {
    if (convertToSeconds(range.startAtTz) >= convertToSeconds(range.endAtTz)) {
      todayGroupRanges.push({ startAtTz: '00:00:00', endAtTz: range.endAtTz });
    }
  });

  return {
    timezone: locTimezone,
    dayOfWeek: dayOfWeekToday,
    ranges: todayGroupRanges,
  };
}

export function getMonitoringConfigText(
  dayMonitoringConfig?: DayLocationMonitoringConfig
) {
  if (
    !dayMonitoringConfig ||
    !dayMonitoringConfig.ranges ||
    dayMonitoringConfig.ranges.length === 0
  ) {
    return 'No monitoring window set';
  }
  let finalText = `Monitoring Hours for ${dayMonitoringConfig.dayOfWeek} (${dayMonitoringConfig.timezone})\n\n`;

  let rangesText = '';
  dayMonitoringConfig.ranges.forEach((range) => {
    rangesText += `${range.startAtTz} - ${range.endAtTz} \n`;
  });

  finalText += rangesText;
  return finalText;
}

export const formatTextWithHeading = (items: string[], heading: string) => {
  if (items.length === 0) return '';
  const formattedItems = items.map((item) => `• ${item}`).join('\n');
  return `${heading}:\n${formattedItems}`;
};

export const updateDefaultValues = (workflow: SOPWorkflow) => {
  const updatedWorkflow = { ...workflow };
  if (!updatedWorkflow.talkdowns) {
    updatedWorkflow.talkdowns = [];
    updatedWorkflow.firstTalkdown &&
      updatedWorkflow.talkdowns.push(updatedWorkflow.firstTalkdown);
    updatedWorkflow.secondTalkdown &&
      updatedWorkflow.talkdowns.push(updatedWorkflow.secondTalkdown);
  }
  const situations = updatedWorkflow.situations ?? [];
  if (situations.length === 0) {
    updatedWorkflow.situations = [...DEFAULT_SOP_SITUATIONS];
  }

  if (
    !updatedWorkflow.quickResolveActions ||
    updatedWorkflow.quickResolveActions.length === 0
  ) {
    updatedWorkflow.quickResolveActions = DEFAULT_SOP_QUICK_RESOLVE;
  }

  return updatedWorkflow;
};

// List of tenants that should see recommendation
export const recommendationsforTenants = [
  'curio-storage',
  'curios-clute',
  'curios-desoto',
  'curios-northline',
  'curios-northside',
  'curios-sloop',
];
