import { Role } from '@hakimo-ui/hakimo/types';

export enum ScreenWidth {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

export const ROLE_MSP_OPERATOR: Role = 'MSP Operator';

export const WEEKDAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const getWeekdays = () =>
  WEEKDAYS.map((day) => ({ name: day, symbol: day[0] }));

export function getPreviousDayOfWeek(day: string): string {
  const dayIndex = WEEKDAYS.indexOf(day);

  // Check if the day is valid
  if (dayIndex === -1) {
    throw new Error(
      `Invalid day: ${day}. Must be one of ${WEEKDAYS.join(', ')}`
    );
  }

  // Calculate the previous day index with wraparound to SATURDAY if day is SUNDAY
  const previousDayIndex = (dayIndex - 1 + WEEKDAYS.length) % WEEKDAYS.length;

  return WEEKDAYS[previousDayIndex];
}

export const DEFAULT_AUDIO_NOTIF_FILE = 'notif-sound-default';
export const tagOptions = ['Police', 'Guards', 'SPOC'];
export const NOTIFICATION_SOUND_LIST = [
  'notif-sound-1',
  'notif-sound-2',
  'notif-sound-3',
  'notif-sound-4',
  'notif-sound-5',
];
