import { Situation, SituationColor } from '@hakimo-ui/hakimo/types';
import { Button, InputField, Modal } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useState } from 'react';

interface CreateUpdateSituationProps {
  onClose: () => void;
  situation: Situation;
  onSubmit: (situation: Situation) => void;
  isNew?: boolean;
}

const situationColorOptions = [
  { id: SituationColor.RED },
  { id: SituationColor.GREEN },
  { id: SituationColor.BLUE },
];
function CreateUpdateSituationModal(props: CreateUpdateSituationProps) {
  const { onClose, situation, onSubmit, isNew } = props;
  const [updatedSituation, setUpdatedSituation] = useState(situation);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUpdatedSituation({ ...updatedSituation, label: value });
  };
  const onChangeColor = (colorId: SituationColor) => () => {
    setUpdatedSituation({
      ...updatedSituation,
      color: colorId,
    });
  };

  const onSubmitUpdatedValue = () => onSubmit(updatedSituation);

  const actions = (
    <>
      <Button
        variant="primary"
        onClick={onSubmitUpdatedValue}
        disabled={updatedSituation.label === ''}
      >
        Submit
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );
  return (
    <Modal
      title={isNew ? 'Create new Situation' : 'Update Situation Details'}
      open
      onClose={onClose}
      footer={actions}
    >
      <div className="min-w-80 space-y-4 p-4 px-8">
        <InputField
          value={updatedSituation.label}
          type="text"
          label="Label"
          onChange={onInputChange}
          autoFocus
        />
        <div className="dark:text-dark-secondary-text mb-1 block text-sm text-gray-700">
          Color
        </div>
        <div className="flex gap-4">
          {situationColorOptions.map((color) => (
            <button onClick={onChangeColor(color.id)}>
              <div
                className={clsx(
                  'flex h-8 w-8 items-center justify-center rounded-full',
                  color.id === SituationColor.RED &&
                    'bg-rose-600 hover:bg-rose-700 dark:bg-rose-400 dark:hover:bg-rose-300',
                  color.id === SituationColor.GREEN &&
                    'bg-teal-600 hover:bg-teal-700 dark:bg-teal-400 dark:hover:bg-teal-300',
                  color.id === SituationColor.BLUE &&
                    'bg-primary-500 hover:bg-primary-700 dark:bg-primary-400 dark:hover:bg-primary-300'
                )}
              >
                {color.id === updatedSituation.color && (
                  <div className="h-2 w-2 rounded-full bg-white"></div>
                )}
              </div>
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default CreateUpdateSituationModal;
