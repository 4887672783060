/* eslint-disable max-lines */
import {
  Situation,
  SituationColor,
  SOPTalkdown,
  SOPTalkdownType,
  SOPWorkflow,
} from '@hakimo-ui/hakimo/types';
import {
  Checkbox,
  InputField,
  Label,
  Tooltip,
} from '@hakimo-ui/shared/ui-base';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import WorkflowFormSituation from './situation/WorkflowFormSituation';
import { WorkflowFormListItem } from './WorkflowFormListItem';
import WorkflowFormTalkdowns from './WorkflowFormTalkdowns';

export const DEFAULT_SOP_SITUATIONS: Situation[] = [
  {
    label: 'Unauthorized Access',
    color: SituationColor.RED,
  },
  {
    label: 'Others',
    color: SituationColor.BLUE,
  },
];

export const DEFAULT_SOP_QUICK_RESOLVE: string[] = [
  'Authorized Person',
  'Authorized Vehicle',
  'False Positive',
  'Others',
];

export interface SOPWorkflowFormItemsType {
  title: string;
  id: keyof SOPWorkflow;
  inputType: string;
  info?: string;
  isFullColSpan?: boolean;
}

export const SOPWorkflowFormItems: SOPWorkflowFormItemsType[] = [
  {
    title: 'Site address',
    id: 'siteAddress',
    inputType: 'string',
  },
  {
    title: 'Site Google map location',
    id: 'siteGoogleMapLocation',
    inputType: 'link',
  },
  {
    title: 'Zero Tolerance',
    id: 'isZeroTolerance',
    inputType: 'boolean',
    info: 'When enabled, operator would be escalating the incident directly. Skipping the talkdowns',
  },
  {
    title: 'Site Exceptions',
    id: 'exceptions',
    inputType: 'list',
    isFullColSpan: true,
  },
  {
    title: 'Situations',
    id: 'situations',
    inputType: 'situation',
    info: 'Emergency & Non-Emergency situations which operators should look out',
    isFullColSpan: true,
  },
  {
    title: 'Notes',
    id: 'notes',
    inputType: 'list',
    isFullColSpan: true,
  },
  {
    title: 'Quick Resolve Actions',
    id: 'quickResolveActions',
    inputType: 'list',
    info: 'These actions will come in 2nd step of SOP workflow. Below mentioned text will be considered as Button text and will resolve the alarm on one click',
    isFullColSpan: true,
  },
  {
    title: 'Talkdown Enabled',
    id: 'isTalkdownEnabled',
    inputType: 'boolean',
  },
  {
    title: 'Talkdowns',
    id: 'talkdowns',
    inputType: 'talkdowns',
    isFullColSpan: true,
  },
  {
    title: 'Escalation Protocol',
    id: 'escalationProtocol',
    inputType: 'list',
    isFullColSpan: true,
  },
  {
    title: 'Points to remember while escalation',
    id: 'escalationPoints',
    inputType: 'list',
    isFullColSpan: true,
  },
];

export const talkdownTypeInfo = `Static Talkdown: Delivers full sentences with immediate text-to-speech playback, requiring no user input.\n
Dynamic Talkdown: Requires user input, such as shirt color, to customize responses. Supports both text-to-speech and recorded delivery.`;

export const updateNullValues = (sopWorkflow: SOPWorkflow) => {
  const updatedSopWorkflow = {
    ...sopWorkflow,
    notes: sopWorkflow?.notes ?? [],
    escalationProtocol: sopWorkflow?.escalationProtocol ?? [],
    quickResolveActions: sopWorkflow?.quickResolveActions ?? [],
  } as SOPWorkflow;
  if (!sopWorkflow.talkdowns) {
    updatedSopWorkflow.talkdowns = [];
    sopWorkflow.firstTalkdown &&
      updatedSopWorkflow.talkdowns.push(sopWorkflow.firstTalkdown);
    sopWorkflow.secondTalkdown &&
      updatedSopWorkflow.talkdowns.push(sopWorkflow.secondTalkdown);
  }
  if (sopWorkflow.isZeroTolerance === undefined) {
    updatedSopWorkflow.isZeroTolerance = false;
  }
  if (!sopWorkflow.situations || sopWorkflow.situations.length === 0) {
    updatedSopWorkflow.situations = DEFAULT_SOP_SITUATIONS;
  }
  if (
    !sopWorkflow.quickResolveActions ||
    sopWorkflow.quickResolveActions.length === 0
  ) {
    updatedSopWorkflow.quickResolveActions = DEFAULT_SOP_QUICK_RESOLVE;
  }
  return updatedSopWorkflow;
};

export const getFormData = (
  isEditing: boolean,
  editedData: SOPWorkflow,
  initData?: SOPWorkflow
) => {
  const isTalkdownEnabled = isEditing
    ? editedData.isTalkdownEnabled
    : initData?.isTalkdownEnabled;
  return isTalkdownEnabled
    ? SOPWorkflowFormItems
    : SOPWorkflowFormItems.filter((item) => item.inputType !== 'talkdowns');
};

const getNonEditableNodes = (
  sopData: SOPWorkflow,
  itemId: keyof SOPWorkflow,
  itemType: string
) => {
  const data = updateNullValues(sopData);
  switch (itemType) {
    case 'link': {
      const val = data[itemId] as string;
      return (
        <a
          href={val}
          target="_blank"
          rel="noreferrer"
          className="text-primary-500 break-words"
        >
          {val}
        </a>
      );
    }

    case 'boolean': {
      const val = data[itemId] as boolean;
      return <Checkbox checked={val} />;
    }
    case 'list': {
      const values = data[itemId] as string[];
      return values.length > 0 ? (
        <div className="col-span-2 space-y-2">
          {values.map((val, i) => (
            <li key={i}>{val}</li>
          ))}
        </div>
      ) : (
        <div>Not applicable</div>
      );
    }
    case 'talkdowns': {
      const val = data[itemId] as SOPTalkdown[];
      return (
        <ol className="col-span-2 list-inside list-decimal space-y-4">
          {val.map((item, i) => (
            <li key={i} className="list-item gap-2">
              <div className="inline-flex items-center gap-2">
                <span>{item.text}</span>
                <span className="flex items-center gap-2">
                  <Label
                    small
                    text={`${item.type.toUpperCase()} Talkdown`}
                    type="info"
                  />
                  <Tooltip
                    text={talkdownTypeInfo}
                    colorModifier="info"
                    position="top-right"
                    size="large"
                  >
                    <InformationCircleIcon className="h-5 w-5" />
                  </Tooltip>
                </span>
              </div>
            </li>
          ))}
        </ol>
      );
    }
    case 'situation': {
      const items = (data[itemId] as Situation[]) ?? [];
      return items.length > 0 ? (
        <WorkflowFormSituation items={items} isEditable={false} />
      ) : (
        <div>Not applicable</div>
      );
    }
    default: // for case type: string
      return <span>{data[itemId] as string}</span>;
  }
};

const getEditableNodes = (
  data: SOPWorkflow,
  itemId: keyof SOPWorkflow,
  itemType: string,
  onChangeData: (val: SOPWorkflow) => void
) => {
  const valueUpdater = (
    id: keyof SOPWorkflow,
    value: string | string[] | boolean | SOPTalkdown[] | Situation[]
  ) => {
    const updatedData = { ...data, [id]: value };
    onChangeData(updatedData);
  };

  switch (itemType) {
    case 'boolean': {
      const val = data[itemId] as boolean;
      return (
        <Checkbox
          checked={val}
          onChange={(checked: boolean) => valueUpdater(itemId, checked)}
        />
      );
    }

    case 'list': {
      const values = data[itemId] as string[];
      return (
        <WorkflowFormListItem
          items={values}
          onChange={(items) => valueUpdater(itemId, items)}
        />
      );
    }

    case 'talkdowns': {
      const val = data[itemId] as SOPTalkdown[];

      return (
        <WorkflowFormTalkdowns
          talkdowns={val}
          onChange={(updatedVal) => valueUpdater(itemId, updatedVal)}
        />
      );
    }

    case 'situation': {
      const val = (data[itemId] as Situation[]) ?? [];
      return (
        <WorkflowFormSituation
          items={val}
          onChange={(updatedVal) => valueUpdater(itemId, updatedVal)}
        />
      );
    }

    default: {
      // case: link and string
      const dataVal = data[itemId] as string;
      return (
        <InputField
          type="text"
          value={dataVal}
          onChange={(e) => valueUpdater(itemId, e.target.value)}
        />
      );
    }
  }
};

export const getWorkflowFormItems = (
  isEdit: boolean,
  itemId: keyof SOPWorkflow,
  itemType: string,
  updatedData: SOPWorkflow,
  onChangeUpdatedData: (val: SOPWorkflow) => void,
  data?: SOPWorkflow
) => {
  if (isEdit) {
    return getEditableNodes(updatedData, itemId, itemType, onChangeUpdatedData);
  } else {
    return data ? getNonEditableNodes(data, itemId, itemType) : '';
  }
};

export const getInitialData = (): SOPWorkflow => {
  return {
    exceptions: [''],
    siteAddress: '',
    siteGoogleMapLocation: '',
    escalationPoints: [''],
    isTalkdownEnabled: true,
    notes: [''],
    situations: [...DEFAULT_SOP_SITUATIONS],
    escalationProtocol: [''],
    quickResolveActions: [...DEFAULT_SOP_QUICK_RESOLVE],
    talkdowns: [
      {
        text: '',
        type: SOPTalkdownType.STATIC,
      },
    ],
  };
};

const VALIDATION_ERRORS: { [key: string]: string } = {
  REQUIRED_SITE_ADDRESS: 'Site address is required',
  REQUIRED_GOOGLE_MAP: 'Site Google map location is required',
  REQUIRED_TALKDOWN_TEXT: 'First talkdown text is required',
  REQUIRED_TALKDOWN_TEXT_SECOND: 'Second talkdown text is required',
  REQUIRED_ESCALATION_POINTS:
    'Escalation points are required. Add at least one.',
  EMPTY_ESCALATION_POINT:
    'Escalation point should not be empty. Provide info or delete the empty ones.',
  REQUIRED_EXCEPTION_POINTS:
    'Exceptions points are required. Add at least one.',
  EMPTY_EXCEPTION_POINT:
    'Exceptions point should not be empty. Provide info or delete the empty ones.',
};

export const validateFormData = (data?: SOPWorkflow) => {
  if (!data) {
    return { isValid: false, message: undefined };
  }
  let message = undefined;

  if (!data.siteAddress) {
    message = VALIDATION_ERRORS['REQUIRED_SITE_ADDRESS'];
  } else if (!data.siteGoogleMapLocation) {
    message = VALIDATION_ERRORS['REQUIRED_GOOGLE_MAP'];
  } else if (!validatePoints('Exception', data.exceptions).isValid) {
    message = validatePoints('Exception', data.exceptions).message;
  } else if (!validatePoints('Escalation', data.escalationPoints).isValid) {
    message = validatePoints('Escalation', data.escalationPoints).message;
  } else if (
    data.isTalkdownEnabled &&
    data.talkdowns &&
    data.talkdowns.length > 0
  ) {
    data.talkdowns.forEach((talkdown, index) => {
      if (!talkdown.text) {
        message = `Talkdown text is required for Talkdown ${index + 1}`;
      }
    });
  }

  return { isValid: !message, message };
};

const validatePoints = (type: string, points: string[]) => {
  if (points.length === 0 && type === 'Escalation') {
    return {
      isValid: false,
      message: VALIDATION_ERRORS[`REQUIRED_${type.toUpperCase()}_POINTS`],
    };
  }
  if (points.some((point) => point === '')) {
    return {
      isValid: false,
      message: VALIDATION_ERRORS[`EMPTY_${type.toUpperCase()}_POINT`],
    };
  }
  return { isValid: true, message: '' };
};
