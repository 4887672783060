/* eslint-disable no-console */
/* eslint-disable max-lines */
import { ScanEventType, WsConnectionState } from '@hakimo-ui/hakimo/types';
import { expose } from 'comlink';

class WebSocketClient {
  private connectionDetails: { ws?: WebSocket } = {
    ws: undefined,
  };
  private batchEventTypes: string[] = [
    ScanEventType.DETECTION,
    ScanEventType.HISTORY,
  ];

  private batchedMessages: Map<string, any[]> = new Map();
  private messageHandler?: (data: any) => void;
  private connectionStatusHandler?: (data: any) => void;

  constructor() {
    // Every 1 second, flush the batched messages
    setInterval(() => {
      this.flushBatchedMessages();
    }, 1000);
  }

  setMessageHandler(callback: (data: any) => void) {
    this.messageHandler = callback;
  }

  setConnectionStatusHandler(
    callback: (data: { status: WsConnectionState }) => void
  ) {
    this.connectionStatusHandler = callback;
  }

  /**
   * Flushes and sends the batched messages via postMessage.
   */
  private flushBatchedMessages(): void {
    this.batchedMessages.forEach((events, eventType, map) => {
      if (events && events.length > 0) {
        // Send the batched messages
        this.messageHandler?.({ type: eventType, data: events });
        // Clear the batch for this event type
        map.set(eventType, []);
      }
    });
  }

  connect(url: string, accessToken?: string) {
    const ws = new WebSocket(url, [accessToken ?? '']);
    this.connectionDetails = {
      ws,
    };

    ws.onopen = () => {
      this.connectionStatusHandler?.({ status: WsConnectionState.CONNECTED });
    };

    ws.onmessage = (event: MessageEvent) => {
      // do some batching or processing here
      try {
        // Parse the incoming data (assumed to be JSON) and extract event_type.
        const parsedData = JSON.parse(event.data);
        const eventType = parsedData.event_type;
        if (!eventType) {
          console.warn('No event_type found in message', parsedData);
          return;
        }
        if (this.batchEventTypes.includes(eventType)) {
          let eventBatch = this.batchedMessages.get(eventType);
          if (!eventBatch) {
            eventBatch = [];
          }
          eventBatch.push(parsedData);
          this.batchedMessages.set(eventType, eventBatch);
        } else {
          // no batching required
          this.messageHandler?.({ type: eventType, data: parsedData });
        }
      } catch (error) {
        console.error('Error parsing message data in scan ws', error);
      }
    };

    ws.onclose = () => {
      this.connectionStatusHandler?.({ status: WsConnectionState.CLOSED });
    };

    ws.onerror = () => {
      this.connectionStatusHandler?.({ status: WsConnectionState.ERROR });
    };

    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error(`Connection timeout for WS`));
      }, 20000); // 20 second timeout

      ws.onopen = () => {
        clearTimeout(timeoutId);
        resolve('');
      };

      ws.onerror = (error) => {
        clearTimeout(timeoutId);
        reject(error);
      };
    });
  }

  disconnect(): void {
    const ws = this.connectionDetails.ws;
    if (ws) {
      ws.close();
    }
  }

  /**
   * Sends a message to a WebSocket.
   *
   * @param {string} message - The message to send.
   */
  send(message: string): void {
    const ws = this.connectionDetails.ws;

    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(message);
    } else {
      console.error(`Web socket connection not opened`);
    }
  }
}
const wsClient = new WebSocketClient();
expose(wsClient);

export type { WebSocketClient };
