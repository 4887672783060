import { DetectionType } from '@hakimo-ui/hakimo/types';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export const personDetectedOptions = [
  'Authorized Employee(s)/Cleaner(s)',
  'Authorized Security Guard',
  'Authorized Resident',
  'Law Enforcement on site',
  'Person Off-site',
  'Deterred with Talkdown',
  'Other',
];

export const vehicleDetectedOptions = [
  'Employee Vehicle',
  'Security Vehicle',
  'Law Enforcement Vehicle',
  'Delivery Truck',
  'Vehicle Off-site',
  'Deterred with Talkdown',
  'Other',
];

export const incorrectDetectionOptions = [
  'No Person',
  'No Vehicle movement / Stationary Vehicle',
  'Animal onsite',
  'Other',
];

const commentOptionsMap = {
  [DetectionType.PERSON]: personDetectedOptions,
  [DetectionType.VEHICLE]: vehicleDetectedOptions,
  [DetectionType.INCORRECT_DETECTION]: incorrectDetectionOptions,
};

export const getStatusCommentOptions = (
  detectionType: DetectionType
): Selectable[] => {
  const options = commentOptionsMap[detectionType];
  return options.map((item) => ({ id: String(item), name: item }));
};

export const getCommentPrefix = (type?: DetectionType) => {
  switch (type) {
    case DetectionType.PERSON:
      return 'Person Present';
    case DetectionType.VEHICLE:
      return 'Moving Vehicle';
    case DetectionType.INCORRECT_DETECTION:
      return 'False Positive';
    default:
      return '';
  }
};
